import { Card, Col, Form, Row, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Fixed2 } from "../../../../../common/utils/common.utils";
import {
  DateInput,
  FormButton,
  FormInput,
  NumberInput,
} from "../../../FormItem/FormItems";
import LoadingIndicator from "../../../spinner/LoadingIndicator";
import InvoiceVoidInputs from "./InvoiceVoidInputs";
import {
  IPostVoid,
  useLazyGetDetailsForVoidQuery,
  usePostVoidMutation,
} from "./VoidApiEndpoints";

type Props = {
  invoice_category_id?: number;
  invoice_id: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TabInvoiceVoid = ({ invoice_id, setIsModalOpen }: Props) => {
  const [form] = Form.useForm();

  const [getDetails, { isFetching, isLoading, data }] =
    useLazyGetDetailsForVoidQuery();

  const [postVoid, { isError, isSuccess, isLoading: PostLoading, error }] =
    usePostVoidMutation();

  const backendError: any = error;

  const voidDetails = data?.data;

  useEffect(() => {
    if (invoice_id) getDetails(String(invoice_id));
  }, [invoice_id]);

  const onFinish = async (values: Submit) => {
    const body: IPostVoid = {
      invoice_void_date: values.void_date,
      client_charge: values.client_charge,
      net_total: values.net_total,
      invoice_no: values.invoice_no,
      cate_id: data?.data?.cate_id!,
      void_amount: values.void_amount,
      void_discount: values.void_discount,

      comb_client: voidDetails?.comb_client!,
      invoice_vendors: values?.invoice_vendors?.map((item) => ({
        comb_vendor: item.comb_vendor,
        vendor_charge: item.vendor_charge,
        airticket_ticket_no: item.airticket_ticket_no,
        cost_price: Fixed2(item.cost_price),
        sales: Fixed2(item.sales),
        airticket_id: item.airticket_id,
      })),
    };

    await postVoid({ body: body, id: invoice_id });
  };

  const vendorInfo = useWatch("invoice_vendors", form);

  const formattedTicketInfo =
    voidDetails?.vendors?.length &&
    voidDetails.vendors?.map((item) => {
      return {
        vendor_name: item.vendor_name,
        comb_vendor: item.comb_vendor,
        cost_price: Fixed2(item.cost_price),
        airticket_discount_total: Fixed2(item.airticket_discount_total),
        airticket_ticket_no: item.airticket_ticket_no,
        sales: Fixed2(item.sales),
        airticket_id: item.airticket_id,
      };
    });

  //   CLIENT AND VENDOR TOTAL REFUND AMOUNT
  useEffect(() => {
    if (vendorInfo) {
      form.setFieldsValue({
        vendor_total_void: vendorInfo?.reduce(
          (a: any, b: any) => a + Fixed2(b?.vendor_charge),
          0
        ),
        net_total: vendorInfo?.reduce(
          (a: any, b: any) => a + Fixed2(b?.sales),
          0
        ),
        // void_discount: vendorInfo?.reduce(
        //   (a: any, b: any) => a + Fixed2(b?.airticket_discount_total),
        //   0
        // ),
      });
    }
  }, [vendorInfo]);

  useEffect(() => {
    if (voidDetails) {
      form.setFieldsValue({
        client_name: voidDetails?.client_name,
        invoice_no: voidDetails?.invoice_no,
        void_date: dayjs(),
      });

      form.setFieldValue("void_discount", Fixed2(voidDetails.invoice_discount));
      form.setFieldValue("invoice_vendors", formattedTicketInfo);
    }
  }, [voidDetails]);

  const client_price = useWatch("net_total", form);
  const client_discount = useWatch("void_discount", form);

  const calculate_void_amount = Fixed2(client_price) - Fixed2(client_discount);

  useEffect(() => {
    form.setFieldsValue({ void_amount: calculate_void_amount || 0 });
  }, [calculate_void_amount]);

  useEffect(() => {
    if (isError) {
      message.error(backendError.data?.message || "Something went wrong!");
    } else if (isSuccess) {
      setIsModalOpen(false);
      message.success("The ticket has been voided.");
    }
  }, [isError, isSuccess]);

  return (
    <>
      {(isLoading || isFetching) && <LoadingIndicator />}
      <Form layout="vertical" labelAlign="left" onFinish={onFinish} form={form}>
        <Card size="small" style={{ width: "100%" }}>
          <Row gutter={20}>
            <FormInput label="Client" name={"client_name"} disabled size={8} />

            <FormInput
              label="Invoice No."
              name={"invoice_no"}
              readonly
              size={8}
            />

            <DateInput
              label="Void Date."
              name={"void_date"}
              size={8}
              required
            />

            {/* Nest Form.List */}
            <Col lg={24}>
              <Form.Item>
                <Form.List name={["invoice_vendors"]}>
                  {(subFields, subOpt) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 12,
                      }}
                    >
                      {subFields?.map((subField, index) => (
                        <InvoiceVoidInputs
                          key={index}
                          subField={subField}
                          subOpt={subOpt}
                          form={form}
                        />
                      ))}
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </Col>

            <FormInput
              label="Client Price"
              name={"net_total"}
              readonly
              size={8}
            />
            <NumberInput
              label="Client Discount"
              name={"void_discount"}
              size={8}
            />
            <FormInput
              label="Void Amount"
              name={"void_amount"}
              readonly
              size={8}
            />

            {/* CLIENT REFUND INFORMATION */}
            <NumberInput
              label="Client Void Charge"
              name={"client_charge"}
              size={8}
            />

            {/* VENDOR REFUND INFORMATION */}
            <FormInput
              name={"vendor_total_void"}
              label="IATA Void Charge"
              size={8}
              readonly
            />

            <FormButton
              label="Submit"
              loading={isLoading || isFetching || PostLoading}
            />
          </Row>
        </Card>
      </Form>
    </>
  );
};

export default TabInvoiceVoid;

export const showModal = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(true);
};

export const handleOk = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

export const handleCancel = (
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsModalOpen(false);
};

interface Submit {
  client_name: string;
  void_amount: number;
  void_discount: number;
  net_total: number;
  invoice_no: string;
  client_charge: number;
  void_date: string;
  invoice_vendors: InvoiceVendor[];
  vendor_total_void: number;
}

interface InvoiceVendor {
  vendor_name: string;
  comb_vendor: string;
  cost_price: number;
  airticket_ticket_no: string;
  vendor_charge: number;
  airticket_id: number;
  sales: string;
}
