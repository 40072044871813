import { Alert, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Fixed2 } from '../../../common/utils/common.utils';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import AirticketPaxPassportDetails from '../../IATA_AIR_TICKET/Components/AirticketPaxPassportDetails';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import InvNonComFlightDetails from '../components/InvNonComFlightDetails';
import InvNonComFormHeader from '../components/InvNonComFormHeader';
import InvNonComTicketInfo from '../components/InvNonComTicketInfo';
import PostOverviewNonCom from '../components/PostOverview';
import { INonComFormSubmit } from '../types/nonInvoiceAirTicketTypes';

type Props = {};

const NewInvoiceNonComission = ({ }: Props) => {
  // @States
  const [invoices, setInvoices] = useState<INonComFormSubmit[]>([]);
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [form] = useForm();

  // GET INVOICE No
  const {
    data: invoiceIdData,
    isLoading: InvoiceLoading,
    isSuccess: InvoiceSuccess,
  } = useGetInvoiceNoQuery('ANC');

  const invoiceIdServer = invoiceIdData?.data;

  useEffect(() => {
    // @GET INVOICE NO AND SET
    if (invoiceIdServer) {
      form.setFieldValue('invoice_no', invoiceIdServer);
    }
  }, [invoiceIdServer, InvoiceSuccess]);

  // @HANDLE CALCULATION HERE
  const handleCalculation = () => {
    const airticket_client_price = Fixed2(
      form.getFieldValue('airticket_client_price')
    );
    const airticket_extra_fee = Fixed2(
      form.getFieldValue('airticket_extra_fee')
    );
    const airticket_purchase_price = Fixed2(
      form.getFieldValue('airticket_purchase_price')
    );

    const clientPriceSet = Fixed2(airticket_client_price) + Fixed2(airticket_extra_fee);
    const airticket_profit = Fixed2(clientPriceSet) - Fixed2(airticket_purchase_price);


    if (clientPriceSet) {
      form.setFieldsValue({
        clientPriceSet: Fixed2(clientPriceSet),
      });
    }

    if (airticket_purchase_price) {
      form.setFieldsValue({
        purchasePriceSet: Fixed2(airticket_purchase_price),
      });
    }


    if (airticket_profit) {
      form.setFieldsValue({
        airticket_profit: airticket_profit,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      dialCode: '+88',
      invoice_sales_date: dayjs(),
      airticket_issue_date: dayjs(),
    });
  }, []);

  // @HANDLE SUBMIT
  const handleSubmit = (e: INonComFormSubmit) => {
    // SET DATA TO STATE
    if (!(editIndex || editIndex === 0)) {
      setInvoices((prev) => [...prev, e]);
    } else {
      // @Handle Edit
      setInvoices(
        invoices.map((item, index) => {
          if (index === editIndex) {
            return e;
          }
          return item;
        })
      );
      setEditIndex(undefined);
    }

    // After add notiication scroll to top and reset fields
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const oldValues = form.getFieldsValue([
      'invoice_combclient_id',
      'invoice_agent_id',
      'invoice_sales_date',
      'invoice_no',
      'invoice_sales_man_id',
    ]);
    form.resetFields();
    form.setFieldsValue({
      dialCode: '+88',
      invoice_sales_date: dayjs(),
      airticket_issue_date: dayjs(),
      invoice_no: oldValues.invoice_no,
      invoice_agent_id: oldValues.invoice_agent_id,
      invoice_combclient_id: oldValues.invoice_combclient_id,
      invoice_sales_man_id: oldValues.invoice_sales_man_id,
    });
  };

  return (
    <ClientsStyle>
      {InvoiceLoading && <LoadingIndicator />}
      <BreadCrumb arrOfOption={['Air Ticket', 'Non Commission']} />

      <div className='separator'>
        <div className='left_side'>
          <Form
            form={form}
            labelAlign='left'
            layout='vertical'
            onFinish={handleSubmit}
            onFinishFailed={(value) => {
              message.error(
                `Enter all required (${value.errorFields.length}) Fields`
              );
            }}
          >
            {/* INVOICE HEADER */}
            <InvNonComFormHeader form={form} />

            {editIndex || editIndex === 0 ? (
              <>
                <br />
                <Alert
                  message={`Update Active -- ${invoices[editIndex]?.airticket_ticket_no}`}
                />
              </>
            ) : (
              ''
            )}

            {/* TICKET INFORMATION */}
            <InvNonComTicketInfo
              form={form}
              handleCalculation={handleCalculation}
              invoices={invoices}
              editIndex={editIndex}
            />

            {/* PAX DETAILS */}

            <AirticketPaxPassportDetails form={form} formName='pax_passport' />

            {/* FLIGHT DETAILS */}
            <InvNonComFlightDetails form={form} />

            <FormButton label='Add New' />
          </Form>
        </div>

        <div className='right_side'>
          {/* @PREVIEW AND CREATE POST RIGHT SIDE TABLE HERE */}
          <PostOverviewNonCom
            form={form}
            invoices={invoices}
            setEditIndex={setEditIndex}
            setInvoices={setInvoices}
            editIndex={editIndex}
          />
        </div>
      </div>
    </ClientsStyle>
  );
};
export default NewInvoiceNonComission;
const ClientsStyle = styled.div`
  .separator {
    display: flex;
    gap: 25px;
  }

  .left_side {
    width: 70%;
  }

  .right_side {
    width: 25%;
    position: fixed;
    right: 10px;
  }

  .separator {
    @media only screen and (max-width: 1207px) {
      flex-direction: column !important;
    }
  }
  .left_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
    }
    @media only screen and (min-width: 1208px) {
      width: 67% !important;
    }
    @media only screen and (min-width: 1400px) {
      width: 68% !important;
    }
    @media only screen and (min-width: 1500px) {
      width: 69% !important;
    }
    @media only screen and (min-width: 1600px) {
      width: 70% !important;
    }
  }
  .right_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
      position: static;
    }
    @media only screen and (max-height: 920px) {
      position: sticky;
    }
  }

  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .invoice_calculation {
    @media only screen and (max-width: 990px) {
      flex-direction: column !important;
    }
  }
  .invoice_calculation_right {
    @media only screen and (max-width: 990px) {
      width: 100% !important;
      margin-left: 0px !important;
    }
  }
  .invoice_calculation_left {
    @media only screen and (max-width: 990px) {
      width: 100% !important;
    }
  }
`;
