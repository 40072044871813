import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Space, Table, Typography } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import useQueryParam from "../../../../common/hooks/useQueryParams";
import { Fixed2, formatNumber } from "../../../../common/utils/common.utils";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useGetDailySalesReportQuery,
  useLazyGetExcelDailySalesReportQuery,
} from "../endpoints/DailySalesReportEndpoints";
import { ISubmitDailySalesReport } from "../types/DailySalesReportTypes";
import { DailySalesReportColumn } from "../utils/DailySalesReportColumn";

const DailySalesReport = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();

  const [fetchExcel] = useLazyGetExcelDailySalesReportQuery();

  const [getData, SetGetData] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  const [formDate, setFromDate] = useQueryParam<string, string>(
    "from_date",
    dayjs().format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useQueryParam<string, string>(
    "to_date",
    dayjs().format("YYYY-MM-DD")
  );

  const [combClient, setCombClient] = useQueryParam<string, string>(
    "invoice_combclient_id",
    "all"
  );
  const [employeeId, setEmployeeId] = useQueryParam<string, string>(
    "employee_id",
    "all"
  );
  const [productId, setProductId] = useQueryParam<string, string>(
    "product_id",
    "all"
  );
  const [dueType, setDueType] = useQueryParam<string, string>(
    "due_type",
    "All"
  );

  const date_range_value = useWatch(["date_range"], form);

  // Set Default Value
  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    form.setFieldValue("invoice_combclient_id", combClient);
    form.setFieldValue(
      "employee_id",
      employeeId !== "all" ? Number(employeeId) : employeeId
    );
    form.setFieldValue(
      "product_id",
      productId !== "all" ? Number(productId) : productId
    );
    setParamData({
      date_range: `?from_date=${dayjs()}&to_date=${dayjs()}`,
      id: "",
    });

    form.submit();
  }, []);

  const location = useLocation();

  const { data, isLoading, isFetching } = useGetDailySalesReportQuery(
    {
      ...pagination,
      from_date: formDate,
      to_date: toDate,
      comb_client: combClient,
      employee_id: employeeId,
      product_id: productId,
      due_type: dueType,
    },
    { skip: getData, refetchOnFocus: true }
  );

  let total_sales: number = Fixed2(data?.data?.total_sales);
  let total_cost: number = Fixed2(data?.data?.total_cost);
  let total_collection: number = Fixed2(data?.data?.total_collection);
  let total_due: number = Fixed2(data?.data?.total_due);
  let total_discount: number = Fixed2(data?.data?.total_discount);
  let invoice_sub_total: number = Fixed2(data?.data?.invoice_sub_total);
  let total_profit: number = total_sales - total_cost;

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get("daily");

    const monthFormDate = searchParams.get("from_date");
    const monthToDate = searchParams.get("to_date");
    console.log(monthFormDate, monthToDate);

    const yearFormDate = searchParams.get("y_from_date");
    const yearToDate = searchParams.get("y_to_date");

    if (dailyValue) {
      handleFinish({
        date_range: [dailyValue, dailyValue],
        employee_id: "all",
        invoice_combclient_id: "all",
        product_id: "all",
        due_type: "all",
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue("date_range", [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      handleFinish({
        date_range: [monthFormDate, monthToDate],
        employee_id: "all",
        invoice_combclient_id: "all",
        product_id: "all",
        due_type: "all",
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue("date_range", [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      handleFinish({
        date_range: [yearFormDate, yearToDate],
        employee_id: "all",
        invoice_combclient_id: "all",
        product_id: "all",
        due_type: "all",
      });
    }
  }, [location.search]);

  const handleFinish = (value: ISubmitDailySalesReport) => {
    setCombClient(value.invoice_combclient_id);
    setEmployeeId(value.employee_id);
    setProductId(value.product_id);
    setDueType(value.due_type);
    setFromDate(dayjs(value.date_range[0]).format("YYYY-MM-DD"));
    setToDate(dayjs(value.date_range[1]).format("YYYY-MM-DD"));

    SetGetData(false);

    setPagination((prev) => ({ ...prev, current: 1, pageSize: 50 }));
  };

  useEffect(() => {
    if (formDate && toDate) {
      setParamData({
        date_range: `from_date=${formDate}&to_date=${toDate}`,
        id: "",
      });
    }
  }, [formDate, toDate]);
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // DAILY SALES REPORT COLUMNS

  const salesData = data?.data?.data?.map((item, index) => ({
    ...item,
    key: index + 1,
  }));

  const jsx_com = (
    <>
      <FormHeaderTitle title="SALES REPORT" />
      {
        <Table
          size="small"
          bordered
          columns={DailySalesReportColumn({ pagination, paramData })}
          dataSource={salesData}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.count && data.count > 20
              ? {
                ...pagination,
                total: data.count,
                showSizeChanger: true,
                pageSizeOptions: ["20","50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
              : false
          }
          scroll={{ y: 700 }}
          summary={(_) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Cell index={0} colSpan={6}>
                  <div className="report_total_footer">
                    <Typography.Text strong>TOTAL </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1}>
                  <div className="report_total_footer">
                    <Typography.Text strong className="nowrap color-sales ">
                      {formatNumber(invoice_sub_total)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1}>
                  <div className="report_total_footer">
                    <Typography.Text strong className="color-collection nowrap">
                      {formatNumber(total_collection)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={4}>
                  <div className="report_total_footer">
                    <Typography.Text strong className="color-discount nowrap">
                      {formatNumber(total_discount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={5}>
                  <div className="report_total_footer">
                    <Typography.Text strong className="nowrap">
                      {formatNumber(total_sales)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={6}>
                  <div className="report_total_footer">
                    <Typography.Text strong className="color-purchase nowrap">
                      {formatNumber(total_cost)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2}>
                  <div className="report_total_footer">
                    <Typography.Text strong className="color-due nowrap">
                      {formatNumber(total_due)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={7}>
                  <div className="report_total_footer">
                    <Typography.Text
                      strong
                      style={{ color: total_profit > 0 ? "green" : "red" }}
                      className="nowrap"
                    >
                      {formatNumber(total_profit)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary>
            );
          }}
        />
      }
    </>
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Sales_Report${date_range_value}`,
  });

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Sales Report" }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Sales Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        {/*      <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button> */}

        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              from_date: formDate as string,
              to_date: toDate as string,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form onFinish={handleFinish} form={form} layout="vertical">
        <ReportsHeader
          size={5}
          showSelectClients
          showSelectEmployee
          showSelectProduct
          dueType
          date_range
          title="Sales Report"
          required
        />
      </Form>
      {jsx_com}

      {print_content}
    </>
  );
};

export default DailySalesReport;
