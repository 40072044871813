import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { Button, Col, DatePicker, Input, Row, Select, Table } from "antd";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { perProps } from "../../../../common/types/commonTypes";
import AddNewContactModal from "../modals/AddNewContactModal";
import { useGetAllContactsQuery } from "../Endpoints/contactsEndpoints";
import { contactColumns } from "../utils/utils";
import { PlusOutlined } from "@ant-design/icons";
import { setCommonModal } from "../../../../common/slices/modalSlice";
import { removeSpaceBeforeWord } from "../../../../components/common/Invoice/InvoiceListHeader";
import { DateInput } from "../../../../components/common/FormItem/FormItems";
import dayjs from "dayjs";
import { rangePresets } from "../../../../common/utils/common.utils";
import { IContactFilters } from "../types/contactTypes";

const Contacts = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  function generateContinuous16DayPeriods() {
    const today = dayjs();
    const startOfMonth = today.startOf("month");

    const daysSinceMonthStart = today.diff(startOfMonth, "day");
    const periodIndex = Math.floor(daysSinceMonthStart / 16);
    const periodStart = startOfMonth.add(periodIndex * 16, "day");
    const days = [];

    for (let i = 0; i < 16; i++) {
      const currentDay = periodStart.add(i, "day");
      if (currentDay.month() !== periodStart.month()) {
        break;
      }
      days.push(currentDay.format("YYYY-MM-DD"));
    }

    return days;
  }

  function getFirstAnd16thDayOfPeriod() {
    const days = generateContinuous16DayPeriods();
    return [days[0], days[days.length - 1]];
  }

  const [filterData, setFilterData] = useState<IContactFilters>({
    search: "",
    ac_account_type: "",
    start_date: getFirstAnd16thDayOfPeriod()[0],
    end_date: getFirstAnd16thDayOfPeriod()[1],
    sent_status: "",
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const {
    isLoading,
    data: contacts,
    isFetching,
  } = useGetAllContactsQuery({
    ...pagination,
    ...filterData,
  });

  const addContact = () => {
    dispatch(
      setCommonModal({
        title: "Add New Contacts",
        content: <AddNewContactModal />,
        show: true,
        width: 500,
      })
    );
  };

  const handleSearch = (e: any) => {
    setFilterData({
      ...filterData,
      search: removeSpaceBeforeWord(e.target.value),
    });
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={["Configuration", "Contacts"]}
        // refetch={refetch}
        reloaderSize="small"
      />

      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: "25px" }}
        justify={"space-between"}
      >
        <Col sm={24} xs={24} lg={6}>
          {permission?.["create:any"] && (
            <Button type="primary" onClick={addContact}>
              <PlusOutlined />
              Add New Contacts
            </Button>
          )}
        </Col>
        <Col sm={24} xs={24} lg={18}>
          <Row justify={"end"} gutter={[10, 10]}>
            <Col xs={24} sm={12} md={6} lg={6} xxl={4}>
              <Select
                allowClear
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    sent_status: e,
                  })
                }
                style={{ width: "100%" }}
                placeholder="Sent type"
                options={[
                  { value: "sent", label: "Sent Gift" },
                  { value: "not_sent", label: "Not Sent Gift " },
                ]}
              />
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xxl={5}>
              <DatePicker.RangePicker
                presets={rangePresets}
                value={[
                  dayjs(filterData.start_date),
                  dayjs(filterData.end_date),
                ]}
                onChange={(e: any) => {
                  if (e) {
                    setFilterData({
                      ...filterData,
                      start_date: e[0].format("YYYY-MM-DD"),
                      end_date: e[1].format("YYYY-MM-DD"),
                    });
                  } else {
                    setFilterData({
                      ...filterData,
                      start_date: getFirstAnd16thDayOfPeriod()[0],
                      end_date: getFirstAnd16thDayOfPeriod()[1],
                    });
                  }
                }}
              />
            </Col>

            <Col xs={24} sm={12} md={6} lg={6} xxl={4}>
              <Select
                allowClear
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    ac_account_type: e,
                  })
                }
                style={{ width: "100%" }}
                placeholder="Account type"
                options={[
                  { value: "client", label: "Client" },
                  { value: "vendor", label: "Vendor" },
                  { value: "airline", label: "Airline" },
                  { value: "visa", label: "Visa" },
                ]}
              />
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xxl={4}>
              <Input
                placeholder="🔍 Search..."
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size="small"
        bordered
        rowKey={"account_contact_id"}
        pagination={
          contacts?.count !== undefined && contacts?.count < 20
            ? false
            : {
                ...pagination,
                total: contacts?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20","50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
        dataSource={contacts?.data}
        columns={contactColumns({
          permission,
          pagination,
          filterData,
        })}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default Contacts;
