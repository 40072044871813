import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import {
  Fixed2,
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectCombined } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { disabledFeatureDate } from '../../../../helpers/CapitalizeFirstLetter';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllDueAdvanceCombinedQuery } from '../endpoints/Total_Due_Advance_CombinedEndpoints';
import { ITotal_Due_Advance_FormData } from '../types/Total_Due_Advanced_CombinedTypes';
import { Total_Due_Advance_CombinedUtils } from '../utils/Total_Due_Combined_Utils';

type Props = {};

function Total_Due_Advance_Combined({ }: Props) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });

  const [fetchTotalDueAdvance, { data, isLoading }] =
    useLazyGetAllDueAdvanceCombinedQuery();

  const onFinish = async (values: ITotal_Due_Advance_FormData) => {
    const setFrom_date = dayjs(values.date_range).format('YYYY-MM-DD');
    const date_range = `payment_date=${setFrom_date}`;
    const body = {
      combine_id: values.client_id,
      date_range: date_range,
    };
    setDate_range_value(date_range);
    fetchTotalDueAdvance({
      combine_id: body.combine_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.combine_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  const to_date = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `payment_date=${to_date}`;
    form.setFieldValue('date_range', dayjs());
    form.setFieldValue('client_id', 'all');
    fetchTotalDueAdvance(
      {
        combine_id: 'all',
        query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
      },
      true
    );
    setExcelQuery({
      Id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  //pagination--start---
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: data?.count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data?.count },
    }));
  }, [data?.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ITotal_Due_Advance_FormData = form.getFieldsValue();
    const setTo_date = dayjs(values.date_range).format('YYYY-MM-DD');
    const date_range = `payment_date=${setTo_date}`;
    const body = {
      combine_id: values.client_id,
      date_range: date_range,
    };

    fetchTotalDueAdvance({
      combine_id: body.combine_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.combine_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Due_Advance_Report_${date_range_value}`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <FormHeaderTitle title='Total Due/Advance (Combined)' />
      <ConfigProvider>
        <Typography.Text style={{ margin: 10 }}>
          You have total: {data?.count} rows
        </Typography.Text>
        <Table
          size='small'
          bordered
          pagination={
            data?.count !== undefined && data?.count > 20
              ? tableParams.pagination
              : false
          }
          dataSource={data?.data}
          columns={Total_Due_Advance_CombinedUtils(queryData)}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
          summary={(tableData) => {
            let totalAdvance = 0;
            let totalDue = 0;

            tableData.map(({ comtrxn_lbalance }) => {
              const balance = Number(comtrxn_lbalance);
              if (balance > 0) {
                totalAdvance += balance;
              } else if (balance < 0) {
                totalDue += balance;
              }
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={4}>
                  <div
                    style={{
                      alignContent: 'end',
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    Total:
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'right',
                      }}
                    >
                      {Fixed2(Math.abs(totalDue))}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: 'green',
                        textAlign: 'right',
                      }}
                    >
                      {Fixed2(totalAdvance)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {' '}
                  {
                    <Typography.Text
                      strong
                      style={{
                        color: totalAdvance + totalDue > 0 ? 'green' : 'red',
                      }}
                    >
                      {Math.abs(totalAdvance + totalDue)}
                    </Typography.Text>
                  }{' '}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (Combined)' }}
      />
    </div>
  );
  return (
    <div>
      {!id && (
        <BreadCrumb arrOfOption={['Report', 'Total Due/Advance (Combined)']} />
      )}
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (data?.count)
              fetchExcel({
                client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: 'totalDueAdvance/combined_client',
                excelName: 'total_due_advance_combinedclients',
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row gutter={10}>
          <SelectCombined
            required
            name='client_id'
            size={6}
            mdSize={10}
            disabled={id ? true : false}
            label='Select Client'
            placeholder='Select Client'
            showAll
            offDropDown
          />
          <Col span={6} xs={24} sm={24} md={10} lg={6}>
            <Form.Item
              label='Select Date'
              name='date_range'
              rules={[{ required: true, message: ` Date is required!` }]}
            >
              <DatePicker
                format={'DD-MM-YYYY'}
                allowClear={false}
                style={{ width: '100%' }}
                disabledDate={disabledFeatureDate}
              />
            </Form.Item>
          </Col>
          <Col lg={2} xs={2} sm={2} md={2}>
            <FormButton
              label='Search'
              icon
              textAlign='left'
              loading={isLoading}
            />
          </Col>
        </Row>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
}

export default Total_Due_Advance_Combined;
