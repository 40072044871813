import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Popconfirm, Row, Table } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { checkDate } from '../../../../auth/utils/lib';
import useQueryParam from '../../../../common/hooks/useQueryParams';
import {
  DateInput,
  FormInput,
} from '../../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useBspFileListQuery,
  useDeleteBspFileListMutation,
} from '../api/bspBillApisEndpoints';
import BSPBill_Summary_modal from '../components/BSPBill_Summary_modal';
import UploadBSPBilling from '../components/UploadBSPBilling';
import { BSPBill_File_list } from '../type/BSPType';

type Props = {};

const disableDates = (current = dayjs()) => {
  if (!current) return false;
  const lastDayOfMonth = current.endOf('month').date();
  const isEighth = current.date() === 8;
  const isFifteenth = current.date() === 15;
  const isTwentyThird = current.date() === 23;
  const isLastDayOfMonth = current.date() === lastDayOfMonth;
  return !(isEighth || isFifteenth || isTwentyThird || isLastDayOfMonth);
};

const BSPBill_List = (props: Props) => {
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const date = useWatch('date', form);
  const [dateQuery, setDateQuery, removeUrlParam] = useQueryParam('date', date);
  const [searchQuery, setSearchQuery, removeSearchParam] = useQueryParam<
    string,
    string
  >('search', '');

  useEffect(() => {
    if (checkDate(date)) {
      setDateQuery(dayjs(date).format('YYYY-MM-DD'));
    } else {
      removeUrlParam();
    }

    setPagination({ current: 1, pageSize: 50 });
  }, [date]);

  useEffect(() => {
    if (checkDate(dateQuery)) form.setFieldsValue({ date: dayjs(dateQuery) });
    if (searchQuery !== 'undefined') {
      form.setFieldsValue({ search: searchQuery });
      form.submit();
    } else {
      removeSearchParam();
    }
  }, [dateQuery, searchQuery]);

  const { isLoading, data } = useBspFileListQuery({
    page: pagination.current,
    size: pagination.pageSize,
    date: date && dayjs(date).format('YYYY-MM-DD'),
    search: searchQuery,
  });

  const ticketMap = new Map();

  data?.data?.forEach((item, index) => {
    const { bsp_summary_ref } = item;
    if (!ticketMap.has(bsp_summary_ref)) {
      ticketMap.set(bsp_summary_ref, { prices: [], firstIndex: index });
    }
    ticketMap.get(bsp_summary_ref).prices.push(Number(bsp_summary_ref));
  });

  const formattedData = data?.data?.map((item, index): BSPBill_File_list => {
    const { bsp_summary_ref } = item;
    const ticketData = ticketMap.get(bsp_summary_ref);
    const firstIndex = ticketData.firstIndex;

    const rowSpan = firstIndex === index ? ticketData.prices.length : 0;

    return {
      ...item,
      rowSpan,
    };
  });

  const [
    deleteFile,
    { isLoading: deleteLoading, isError: bspBillDeErr, isSuccess: bspBillSucc },
  ] = useDeleteBspFileListMutation();
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const columns: ColumnsType<BSPBill_File_list> = [
    {
      title: 'Sl No',
      dataIndex: 'slNo',
      key: 'slNo',
      align: 'center',
      render: (curr, data, index) => {
        return index + 1;
      },
    },

    {
      title: 'Created At',
      dataIndex: 'bsp_created_date',
      key: 'bsp_created_date',
      render: (curr) => dayjs(curr).format('DD-MMM-YYYY'),
    },



    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },

    {
      title: 'File Name',
      dataIndex: 'bsp_file_name',
      key: 'bsp_file_name',
    },


    {
      title: 'Billing Date',
      dataIndex: 'bsp_bill_date',
      key: 'bsp_bill_date',
      render: (curr) => dayjs(curr).format('DD-MMM-YYYY'),
    },

    {
      title: 'Summary Ref',
      dataIndex: 'bsp_summary_ref',
      key: 'bsp_summary_ref',
      align: 'center',
      onCell: (record, rowIndex) => {
        return {
          rowSpan: record.rowSpan,
        };
      },

      render: (curr) => {
        return (
          <Button
            onClick={() => {
              setEditInfo(curr);
              setIsModalOpen(!isModalOpen);
            }}
            type='primary'
            size='small'
          >
            Summary
          </Button>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (_, rec) => {
        return (
          <Row gutter={10}>
            <Col>
              <Link
                to={`/reports/bsp-bill-compare/${rec.bsp_id}`}
                state={`/reports/bsp-bill-list`}
              >
                <Button size='small' type='primary'>
                  Reconcile
                </Button>
              </Link>
            </Col>
            <Col>
              <Link to={rec.bsp_file_url} target='_blank'>
                <Button size='small' type='primary'>
                  PDF
                </Button>
              </Link>
            </Col>
            <Col>
              <Popconfirm
                onConfirm={() => deleteFile(String(rec.bsp_id))}
                okButtonProps={{ loading: deleteLoading }}
                title='Sure to delete?'
              >
                <Button size='small' danger>
                  Delete
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const handlePrint = () => { };

  const submit = (value: { date: string; search: string }) => {
    setSearchQuery(value?.search?.trim());
  };

  useEffect(() => {
    if (bspBillDeErr) {
      message.error('Something went wrong');
    } else if (bspBillSucc) {
      message.success('Bill Deleted Successfully Done');
    }
  }, [bspBillDeErr, bspBillSucc]);

  return (
    <>
      <BreadCrumb arrOfOption={['Report', 'Others', 'BSP Bill List']} />

      <BSPBill_Summary_modal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setEditInfo={setEditInfo}
        editInfo={editInfo}
      />

      <Row
        style={{ marginBottom: '1rem' }}
        align={'middle'}
        justify={'space-between'}
      >
        <Col>
          <Row gutter={10} justify={'end'}>
            <Col>
              <Button type='primary' onClick={handlePrint}>
                <PrinterOutlined />
                Print
              </Button>
            </Col>
            <Col>
              <Button type='primary' onClick={() => { }}>
                <FileTextOutlined />
                Excel Report
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <Form form={form} layout='vertical' onFinish={submit}>
            <Row justify={'end'} align={'middle'} gutter={6}>
              <Col span={4}>
                <DateInput
                  size={24}
                  name={'date'}
                  label='Search by date:'
                  disableSpecificDate={disableDates}
                />
              </Col>
              <Col span={4}>
                <FormInput
                  size={24}
                  name={'search'}
                  placeholder='Search Here'
                  label='Search by file name:'
                />
              </Col>

              <Col span={2}>
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{ marginTop: 5 }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <UploadBSPBilling noModal />

      <Row style={{ marginTop: '20px' }}>
        <FormHeaderTitle title='BSP PDF File List' />
      </Row>

      <Table
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
        columns={columns}
        dataSource={formattedData}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        bordered
        size='small'
        className='invoiceBillingTable'
      />
    </>
  );
};

export default BSPBill_List;
