import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import {
  IRefundReportClientDataTypes,
  IRefundReportVendorDataTypes,
} from '../types/refundReportTypes';
//RefundReportColumn
export const RefundReportColumnClient =
  (): ColumnsType<IRefundReportClientDataTypes> => {
    const searchColumnProps =
      useGetSearchColumnProps<IRefundReportClientDataTypes>();
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
        render: (_, data, index) => index + 1,
      },
      {
        title: 'Date',
        dataIndex: 'refund_created_date',
        key: 'refund_created_date',
        render: (_, record) => {
          return `${dayjs(record.refund_created_date).format('DD MMM YYYY')}`;
        },
      },

      {
        title: 'Invoice No',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
        render: (_, data) => {
          const { invoice_no, invoice_category_id, invoice_id } = data;
          return (
            <>
              <Link
                to={`../${
                  findInvoiceRoute(invoice_category_id) +
                  '/' +
                  'details' +
                  '/' +
                  invoice_id
                }`}
              >
                {invoice_no}
              </Link>
            </>
          );
        },
      },
      {
        title: 'Vouchar No',
        dataIndex: 'vouchar_no',
        key: 'vouchar_no',
      },

      {
        title: 'Client Name',
        dataIndex: 'client_name',
        key: 'client_name',
        render: (_, record) => (
          <Link
            to={
              record.invoice_client_id
                ? `/reports/client_ledger?client_query=${record.invoice_client_id}`
                : `/reports/combined_ledger?id=${record.invoice_combined_id}`
            }
          >
            {record.client_name}
          </Link>
        ),
      },

      {
        title: 'Ticket Price',
        dataIndex: 'crefund_ticket_price',
        key: 'crefund_ticket_price',
        render: (_, record) => Fixed2(record.crefund_ticket_price || 0),
        align: 'right',
      },

      {
        title: 'Charge Amount',
        dataIndex: 'ccharge_amount',
        key: 'ccharge_amount',
        render: (_, record) => Fixed2(record.ccharge_amount),
        align: 'right',
      },
      {
        title: 'Refund Amount',
        dataIndex: 'crefund_amount',
        key: 'crefund_amount',
        render: (_, record) => Fixed2(record.crefund_amount),
        align: 'right',
      },

      {
        title: 'Customer Amount',
        dataIndex: 'crefund_return_amount',
        key: 'crefund_return_amount',
        render: (_, record) => Fixed2(record.crefund_return_amount),
        align: 'right',
      },

      {
        title: 'Net Profit/Loss',
        dataIndex: 'crefund_net_profit_losss',
        key: 'crefund_net_profit_losss',
        render: (_, record) =>
          Fixed2(record.ccharge_amount) - Fixed2(record.vendor_charge),
        align: 'right',
      },
    ];
  };
export const RefundReportColumnVendor =
  (): ColumnsType<IRefundReportVendorDataTypes> => {
    const searchColumnProps =
      useGetSearchColumnProps<IRefundReportVendorDataTypes>();
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
        render: (_, data, index) => index + 1,
      },
      {
        title: 'Date',
        dataIndex: 'refund_created_date',
        key: 'refund_created_date',
        render: (_, record) => {
          return `${dayjs(record.refund_created_date).format('DD MMM YYYY')}`;
        },
      },

      {
        title: 'Invoice No',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
      },
      {
        title: 'Vouchar No',
        dataIndex: 'vouchar_no',
        key: 'vouchar_no',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendor_name',
        key: 'vendor_name',
      },
      {
        title: 'Refund Amount',
        dataIndex: 'vrefund_amount',
        key: 'vrefund_amount',
        render: (_, record) => Number(record.vrefund_amount),
        align: 'right',
      },
      {
        title: 'Charge Amount',
        dataIndex: 'vcharge_amount',
        key: 'vcharge_amount',
        render: (_, record) => Number(record.vcharge_amount),
        align: 'right',
      },
      {
        title: 'Return Amount',
        dataIndex: 'vrefund_return_amount',
        key: 'vrefund_return_amount',
        render: (_, record) => Number(record.vrefund_return_amount),
        align: 'right',
      },
    ];
  };
