import { Col, Form, message, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toasterNotification } from "../../../common/utils/ToasterNotification";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import {
  FormButton,
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from "../../../components/common/FormItem/FormItems";
import { SelectDataInput } from "../../../components/common/FormItem/SelectCustomFeilds";
import InvoiceHeaderForm from "../../../components/common/Invoice/InvoiceHeaderForm";
import { useGetInvoiceNoQuery } from "../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useLazyGetExistingClientsQuery,
  usePostExistingClientMutation,
} from "../api/endpoints/reIssueExistingAirTicketEndpoints";
import NewBillingInfoForm from "../Components/NewBillingInfoForm";
import NewOrExistingBtn from "../Components/NewOrExistingBtn";
import PreviousTicketInfo, {
  IExistingClients,
} from "../Components/PreviousTicketInfo";
import { IInvoice_info_Existing } from "../Type/InoviceReissue.interface";

type Props = {};

const ExistingInvoiceReissue = ({}: Props) => {
  const [form] = useForm();

  // get invoice id
  const { data: invoiceIdData } = useGetInvoiceNoQuery("ARI");
  const invoiceIdServer = invoiceIdData?.data;
  useEffect(() => {
    if (invoiceIdServer) {
      form.setFieldsValue({
        invoice_no: String(invoiceIdServer),
        invoice_sales_date: dayjs(),
      });
    }
  }, [invoiceIdServer]);

  // ================ existing clients data ==================
  const [exClientDataGet, { data: exClientsData, isLoading, isSuccess }] =
    useLazyGetExistingClientsQuery();

  const existingClient = exClientsData?.data;

  // ======================= get form value ===================
  const [selectedClient, setSelectedClient] = useState<IExistingClients[]>([]);

  const handleSelectTicket = () => {
    const ticketNo = form.getFieldValue("ticket_no") as number[];
    const selectedTickets = existingClient?.filter((item) =>
      ticketNo.includes(item.airticket_id)
    );
    if (selectedTickets && selectedTickets.length > 0) {
      setSelectedClient(selectedTickets);
    }
  };

  const handleClientChange = () => {
    const client_id = form.getFieldValue("invoice_combclient_id");
    if (client_id) {
      exClientDataGet(client_id);
    }
  };

  // Calculate

  const [
    postExistingClient,
    {
      isLoading: postLoading,
      isError: postError,
      isSuccess: postSuccess,
      error,
      data,
    },
  ] = usePostExistingClientMutation();

  // @Form submit
  const onFinish = async (values: IInvoice_info_Existing) => {
    const { ticket_no, ...all } = values as IInvoice_info_Existing;

    const body = {
      ...all,
      invoice_sales_date: dayjs(values.invoice_sales_date).format("YYYY-MM-DD"),
    };
    await postExistingClient(body);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Submit failed!");
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (postSuccess) {
      toasterNotification(
        "success",
        "Invoice airticket existing client added successfully!"
      );
      navigate(`/reissueairticket/details/${data?.data?.invoice_id}`, {
        state: "/reissueairticket",
      });
    }
    if (postError) {
      toasterNotification("error", "There was an error");
    }
  }, [postLoading]);

  const formattedTicketInfo =
    selectedClient?.length &&
    selectedClient?.map((item) => {
      return {
        airticket_existing_airticket_id: item?.airticket_id,
        airticket_existing_invoiceid: item?.airticket_invoice_id,
        comb_vendor: item?.comb_vendor,
        passport_name: item?.passport_name,
        airticket_issue_date: item?.airticket_issue_date
          ? dayjs(item?.airticket_issue_date)
          : undefined,
        airticket_pnr: item.airticket_pnr,
        airticket_reissue_ticket_no: item.airticket_ticket_no,
        airline_name: item.airline_name,
        vendor_name: item.vendor_name,
      };
    });

  useEffect(() => {
    form.setFieldValue("air_ticket_info", formattedTicketInfo);
  }, [formattedTicketInfo]);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={["Invoice (Air Ticket)", "Existing"]} />

      {/* @NEW CLIENT OR EXISTING CLIENT */}
      <Row justify="center">
        <NewOrExistingBtn />
      </Row>

      <Form
        initialValues={{
          remember: true,
          invoice_discount: 0,
          invoice_service_charge: 0,
          invoice_net_total: 0,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <InvoiceHeaderForm
          clientOnChange={() => {
            handleClientChange();
          }}
          invoice_agent_com_amountFieldName={["invoice_agent_com_amount"]}
          invoice_net_totalFieldName={["invoice_net_total"]}
          form={form}
        />

        <Row justify="center" className="border py-20">
          <SelectDataInput
            maxCount={5}
            allowClear
            mode="multiple"
            data={existingClient?.map((item) => ({
              id: item.airticket_id,
              title: item.airticket_ticket_no,
            }))}
            size={12}
            label="Select Ticket"
            placeholder="Select ticket no"
            onChange={() => handleSelectTicket()}
            name="ticket_no"
            required
          />
        </Row>

        <div className="my-20">
          {selectedClient.length ? (
            <PreviousTicketInfo selectClient={selectedClient} />
          ) : null}

          <Row
            gutter={24}
            justify={"space-between"}
            style={{ position: "relative" }}
          >
            <Col lg={18}>
              <Form.Item>
                <Form.List name={["air_ticket_info"]}>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field, index) => (
                          <>
                            <NewBillingInfoForm
                              key={index}
                              form={form}
                              field={field}
                              index={index}
                              formName={"air_ticket_info"}
                            />
                          </>
                        ))}
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
            </Col>
            <Col
              hidden={formattedTicketInfo ? false : true}
              lg={6}
              style={{
                marginTop: 40,
                padding: "10px 0px !important",
              }}
              className="sticky-column border"
              prefixCls="remove_padding_col"
            >
              <FormHeaderTitle title={`Discount & Service Charge Info`} />

              <NumberInput
                size={24}
                readOnly
                label="Sub Total:"
                name={"invoice_sub_total"}
                placeholder="Overall Sub Total"
              />
              <FormInputItem
                size={24}
                label="Discount:"
                name={"invoice_discount"}
                placeholder="Overall discount"
              />
              <FormInputItem
                size={24}
                label="Service Charge:"
                name={"invoice_service_charge"}
                placeholder="Overall extra charge"
              />
              <FormInputItem
                size={24}
                label="Net Total:"
                name={"invoice_net_total"}
                placeholder="Overall net total"
              />
              <FormInputItem
                hidden
                size={24}
                label="invoice_total_vendor_price:"
                name={"invoice_total_vendor_price"}
                placeholder="Overall net total"
              />
              <FormInputItem
                hidden
                size={24}
                label="invoice_total_profit:"
                name={"invoice_total_profit"}
                placeholder="Overall net total"
              />

              <TextAreaInput size={24} label="Note" name="invoice_note" />

              <FormButton label="Submit" />
            </Col>
          </Row>
        </div>
      </Form>
    </ClientsStyle>
  );
};
export default ExistingInvoiceReissue;
const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .topLeftButton {
    margin-right: 0.5rem;
    margin-bottom: auto;
  }
`;

export type IAir_ticket_info = {
  invoice_combclient_id: string;
  invoice_sales_man_id: number;
  invoice_no: string;
  invoice_sales_date: string;
  invoice_due_date: string;
  invoice_agent_id: number;
  ticket_no: number;
  airticket_ticket_no: string;
  airticket_penalties: number;
  airticket_journey_date: string;
  airticket_fare_difference: number;
  airticket_client_price: number;
  airticket_commission_percent: number;
  airticket_purchase_price: number;
  airticket_ait: string;
  airticket_return_date: string;
  airticket_classes: string;
  invoice_note: string;
  airticket_profit: number;
  airticket_tax: number;
  airticket_extra_fee: number;
  invoice_discount: number;
};
