import { Col, Divider, Row, Typography, message } from "antd";
import { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import AuthSignature from "../component/AuthSignature";
import AutoMail from "../component/AutoMail";
import { AutoSms } from "../component/AutoSms";
import DueWatermark from "../component/DueWatermark";
import InvoiceCalculation from "../component/InvoiceCalculation";
import InvoiceExtraInfo from "../component/InvoiceExtraInfo";
import InvoiceWatermark from "../component/InvoiceWatermark";
import PaidWatermark from "../component/PaidWatermark";
import TotalWorkingDay from "../component/TotalWorkingDay";
import AuthoritySignature from "../component/Translate/AuthoritySignature";
import CurrencySymbol from "../component/Translate/CurrencySymbol";
import CustomerSignature from "../component/Translate/CustomerSignature";
import InvoiceNoText from "../component/Translate/InvoiceNoText";
import SalesDateText from "../component/Translate/SalesDateText";
import { useGetAppConfigQuery } from "../endpoint/appConfigEndpoint";
import { IAppConfig, IGetAppConfig } from "../type/appConfig_Type";
import SecondaryLogo from "../component/SecondaryLogo";

const AppConfig = () => {
  const [configDetails, setConfigDetails] = useState<
    IGetAppConfig | undefined
  >();

  const { Text, Title } = Typography;

  // Get AppConfig
  const { data, isSuccess, isLoading, isFetching, isError } =
    useGetAppConfigQuery();

  useEffect(() => {
    if (isSuccess) {
      setConfigDetails(data?.data);
    } else if (isError) {
      message.error("Something happened to wrong");
    }
  }, [data, isSuccess, isError]);

  return (
    <>
      <BreadCrumb arrOfOption={["Configuration", "App Config"]} />

      <div
        style={{
          maxWidth: 900,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 10,
        }}
      >
        {(isLoading || isFetching) && loadingIndicator}

        <Row justify={"space-between"}>
          <Col>
            <Typography.Title level={4}>
              Instead of in Invoice:
            </Typography.Title>
            <Text type="secondary">
              Change the text on the Invoice page to clarify the payment terms.
            </Text>
          </Col>
          <Col>
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <CustomerSignature configDetails={configDetails} />
              <AuthoritySignature configDetails={configDetails} />
              <CurrencySymbol configDetails={configDetails} />
              <InvoiceNoText configDetails={configDetails} />
              <SalesDateText configDetails={configDetails} />
            </div>
          </Col>
        </Row>
        <Divider />

        <div style={{ marginTop: 20 }}>
          <TotalWorkingDay
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />

        <InvoiceCalculation configDetails={configDetails} />
        <Divider />
        <div style={{ marginTop: 20 }}>
          <DueWatermark
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />

        <div style={{ marginTop: 20 }}>
          <PaidWatermark
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />
        <div style={{ marginTop: 20 }}>
          <AutoMail
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />
        <div style={{ marginTop: 20 }}>
          <AutoSms
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />

        <div style={{ marginTop: 20 }}>
          <AuthSignature
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />
        <div style={{ marginTop: 20 }}>
          <InvoiceWatermark
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>
        <Divider />
        <div style={{ marginTop: 20 }}>
          <SecondaryLogo
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
          />
        </div>

        <Divider />

        <InvoiceExtraInfo configDetails={configDetails} />
      </div>
    </>
  );
};

export default AppConfig;

export const configData: IAppConfig = {
  InvoiceConfig: [
    {
      id: 1,
      defaultTitle: "Customer Signature",
      newTitle: "Client Signature",
    },

    {
      id: 2,
      defaultTitle: "Authority Signature",
      newTitle: "",
    },

    {
      id: 3,
      defaultTitle: "In Word",
      newTitle: "",
    },

    {
      id: 4,
      defaultTitle: "Invoice No",
      newTitle: "",
    },

    {
      id: 5,
      defaultTitle: "Sales Date",
      newTitle: "",
    },

    {
      id: 6,
      defaultTitle: "Mobile",
      newTitle: "",
    },
  ],

  aitCal: "DEFAULT",
  watermark: false,
  signatureURL: "https://i.ibb.co/dQ1wx0K/signature.png",
  signature: false,
  dueWatermark: false,
  paidWatermark: false,
};
