import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Table, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { portraitPageStyle } from "../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import {
  useLazyGetExcelOverAllProfitLossQuery,
  useLazyGetOverAllProfitlossQuery,
} from "../endpoints/profitLossEndpoints";
import {
  IProfitLossCategoriesType,
  IProfitLossDataType,
  IProfitLossFormData,
} from "../types/overAllprofitLossTypes";
import "./ProfitLossTable.css";

type Props = {};
const { Text } = Typography;

const Over_All_Profit_Loss = (props: Props) => {
  const toDayDate = dayjs().format("YYYY-MM-DD");

  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

  const [excelQuery, setExcelQuery] = useState({
    query: "",
  });

  const [form] = useForm();
  const [date_range_value, setDate_range_value] = useState<string>(
    `from_date=${toDayDate}&to_date=${toDayDate}`
  );
  const componentRef = useRef(null);
  const [fetchExcel] = useLazyGetExcelOverAllProfitLossQuery();
  const [fetchOverAllProfitLoss, { data: profitLoss, isLoading, isFetching }] =
    useLazyGetOverAllProfitlossQuery();

  const data = profitLoss?.data;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Profit_Loss_${date_range_value}`,
    pageStyle: portraitPageStyle,
  });

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get("daily");

    const monthFormDate = searchParams.get("from_date");
    const monthToDate = searchParams.get("to_date");

    const yearFormDate = searchParams.get("y_from_date");
    const yearToDate = searchParams.get("y_to_date");

    if (dailyValue) {
      form.setFieldValue("date_range", [dayjs(dailyValue), dayjs(dailyValue)]);
      form.submit();
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue("date_range", [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      form.submit();
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue("date_range", [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      form.submit();
    }
  }, [location.search]);

  const onFinish = async (values: IProfitLossFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    setDateRange({ fromDate: setFrom_date, toDate: setTo_date });
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: any = {
      date_range: date_range,
    };
    setExcelQuery({
      query: date_range,
    });
    setDate_range_value(date_range);
    await fetchOverAllProfitLoss(body);
  };

  useEffect(() => {
    fetchOverAllProfitLoss({
      date_range: [
        dayjs().startOf("month").format("YYYY-MM-DD"),
        dayjs().endOf("month").format("YYYY-MM-DD"),
      ],
    });
  }, []);
  const columns: ColumnsType<IProfitLossDataType> = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (curr) => {
        const res = makeLink(curr, dateRange);
        if (res) return <Link to={res}>{curr}</Link>;
        return curr;
      },
    },
    {
      title: "Expense",
      dataIndex: "total_amount",
      key: "total_amount",
      align: "right",
      width: 200,
      render: (curr, rec) => {
        let expense = rec.trxn_type === "EXPENSE" ? curr : 0;
        return <span className="color-loss"> {formatAmount(expense)} </span>;
      },
    },
    {
      title: "Income",
      dataIndex: "total_amount",
      key: "total_amount",
      align: "right",
      width: 200,

      render: (curr, rec) => {
        let expense = rec.trxn_type === "INCOME" ? curr : 0;
        return <span className="color-profit"> {formatAmount(expense)} </span>;
      },
    },
  ];

  const jsx = (
    <>
      {data?.length ? (
        <Row justify={"center"}>
          <Table
            tableLayout="fixed"
            style={{ width: "900px" }}
            dataSource={data}
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            size="small"
            bordered
            columns={columns}
            pagination={false}
            summary={(pageData) => {
              let totalIncome = 0;
              let totalExpense = 0;

              pageData.forEach(({ trxn_type, total_amount }) => {
                if (trxn_type === "INCOME") {
                  totalIncome += Fixed2(total_amount);
                } else {
                  totalExpense += Fixed2(total_amount);
                }
              });

              const netProfit = totalIncome - totalExpense;

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <div style={{ textAlign: "right", marginRight: 5 }}>
                        <Text strong>TOTAL SUMMARY</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <div style={{ textAlign: "right", marginRight: 5 }}>
                        <Text className="color-loss" strong>
                          {formatAmount(totalExpense)}
                        </Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <div style={{ textAlign: "right", marginRight: 5 }}>
                        <Text className="color-profit" strong>
                          {formatAmount(totalIncome)}
                        </Text>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row>
                    <Table.Summary.Cell index={5}>
                      <div style={{ textAlign: "right", marginRight: 5 }}>
                        <Text strong>
                          OVERALL {netProfit >= 0 ? "PROFIT" : "LOSS"}
                        </Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2} index={6}>
                      <div style={{ textAlign: "right", marginRight: 5 }}>
                        <Text
                          className={
                            netProfit >= 0 ? "color-profit" : "color-loss"
                          }
                          strong
                        >
                          {formatAmountAbs(netProfit)}
                        </Text>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Row>
      ) : (
        <Text strong> No Data Found</Text>
      )}
    </>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx}
        printRef={componentRef}
        title={{ info_title: "", title: "OverAll Profit Loss" }}
        dateRange={dateRange}
      />
    </div>
  );

  return (
    <div>
      <BreadCrumb
        arrOfOption={["Report", "ProfitLoss", "OverAll Profit Loss"]}
      />

      <Row justify={"space-between"} align={"middle"}>
        <Col lg={16}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              date_range: [dayjs().startOf("month"), dayjs().endOf("month")],
            }}
          >
            <ReportsHeader
              size={8}
              required
              date_range_value={date_range_value}
              date_range
            />
          </Form>
        </Col>

        <Col lg={5}>
          <Button type="primary" style={{ margin: 15 }} onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
          <Button
            type="primary"
            onClick={() => {
              fetchExcel({
                query: excelQuery.query,
              });
            }}
          >
            <FileTextOutlined />
            Excel Report
          </Button>
        </Col>
      </Row>
      <Card>{jsx}</Card>
      {print_content}
    </div>
  );
};

export default Over_All_Profit_Loss;

const makeLink = (
  curr: IProfitLossCategoriesType,
  dateRange: {
    fromDate: string;
    toDate: string;
  }
) => {
  const form_date = dateRange.fromDate;
  const to_date = dateRange.toDate;
  const query = `from_date=${form_date}&to_date=${to_date}`;

  if (curr === "SALES") {
    return `/reports/daily_sales_report?${query}`;
  } else if (curr === "PURCHASE") {
    return `/reports/vendor_wise_purchase_and_payment?${query}`;
  } else if (curr === "DISCOUNT") {
    return `/reports/client-discount?${query}`;
  } else if (curr === "VOID CHARGE") {
    return `/reports/void?${query}`;
  } else if (curr === "REFUND PROFIT") {
    return `/reports/refund_report?${query}`;
  } else if (curr === "EXPENSE") {
    return `/reports/head_wise_expense_report?${query}`;
  } else if (curr === "PAYROLL") {
    return `/reports/payroll_reports?${query}`;
  } else if (curr === "INCENTIVE") {
    return `/accounts/incentive?${query}`;
  } else if (curr === "TRANSACTION CHARGE") {
    return `/reports/transaction_charge_report?${query}`;
  } else if (curr === "AIT") {
    return `/reports/ait_report?${query}`;
  } else if (curr === "AGENT PAYMENT") {
    return `/moneyreceipt/agent?${query}`;
  } else if (curr === "NON INVOICE INCOME") {
    return `/accounts/noninvoice?${query}`;
  }
};
