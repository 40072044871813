import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { formatAmountAbs } from "../../../../common/utils/common.utils";
import { dateFormate } from '../../../Dashboard/Utils/formateDate';
import { ITotal_Due_Advance_VendorDataType } from "../types/Total_Due_Advance_VendorTypes";

const { Text } = Typography;
export const Total_Due_Advance_VendorUtils = (queryData: {
  isTrash: "0" | "1";
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_VendorDataType> => {
  return [
    {
      title: "SL.",
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (curr, rec) => {
        return (
          <Link to={`/reports/vendor_ledger?id=${rec.vendor_id}`}>{curr}</Link>
        );
      },
    },
    {
      title: "Mobile No.",
      dataIndex: "vendor_mobile",
      key: "vendor_mobile",
    },
    {
      title: "Email",
      dataIndex: "vendor_email",
      key: "vendor_email",
    },
    {
      title: "Last Trans.",
      dataIndex: "vtrxn_created_date",
      key: "vtrxn_created_date",
      render: (value) => value ? dateFormate(value) : null,
    },
    {
      title: "Present Due",
      dataIndex: "lbalance_amount",
      key: "lbalance_amount",
      align: "right",
      render: (_, record) => Number(record.vtrxn_lbalance) < 0 ? <span className="color-loss">{formatAmountAbs(record.vtrxn_lbalance)} </span> : null
    },
    {
      title: "Present Advance",
      dataIndex: "lbalance_amount",
      key: "lbalance_amount",
      align: "right",
      render: (_, record) => Number(record.vtrxn_lbalance) > 0 ? <span className="color-profit">{formatAmountAbs(record.vtrxn_lbalance)} </span> : null
    },];
};
