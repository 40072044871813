import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import { IAllInvoices } from "../../../../common/types/commonInterfaces";
import {
  useDeleteAirticketMutation,
  useGetAllInvoicesQuery,
} from "../../api/endpoints/airticketInvoiceEndpoints";

import { ArrowLeftOutlined, DollarOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUser } from "../../../../auth/states/userSlice";
import { crud, IPermission } from "../../../../common/types/commonTypes";
import {
  formatAmount,
  rangePresets,
  separateClient,
} from "../../../../common/utils/common.utils";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { removeSpaceBeforeWord } from "../../../../components/common/Invoice/InvoiceListHeader";
import Reload from "../../../../components/common/reload/Reload";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import InvoiceOtherAddMoneyModal from "../../../Invoice(Other)/Modals/InvoiceOtherAddMoneyModal";
import AddClientListInvoice from "./AddClientListInvoice";

type Props = {
  permission?: IPermission;
  addMRPermission: ["*"] | undefined;
};

const { Text } = Typography;

const ListOfInvoiceAirticket = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState<IAllInvoices>();
  const [addClientModal, setAddClientModal] = useState(false);

  // LINKS
  const createLink = "/airticket/add";
  const viewLink = "/airticket/details";
  const editLink = "/airticket/edit";

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const {
    data,
    isFetching,
    refetch: getAllRefetch,
    isLoading: dataIsLoading,
  } = useGetAllInvoicesQuery({
    page: pagination.current,
    size: pagination.pageSize,
    from_date: date[0],
    to_date: date[1],
    search: search,
  });

  const [deleteInvoice, { isError, isSuccess, isLoading, error }] =
    useDeleteAirticketMutation();

  // DELETE HANDLER
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success("Invoice Deleted Successfully!");
    }
    if (isError) {
      setColumnIndex(null);
      message.error("There was an error");
    }
  }, [isError, isSuccess]);

  // NEW ___________________________________

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const ModalOperation = (data: IAllInvoices) => {
    setInvoiceInfo(data);
    setOpenModal(!openModal);
  };

  const columns: ColumnsType<IAllInvoices> = [
    {
      title: "SL.",
      width: 55,
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 50) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      key: "invoice_no",
      width: 100,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoice_reissue_client_type",
      key: "invoice_reissue_client_type",
      width: 80,
      render: (item) => {
        let color = "red";
        if (item === "IUR") {
          color = "cyan";
        } else if (item === "PNR") {
          color = "purple";
        }
        return <Tag color={color}>{item}</Tag>;
      },
    },
    {
      title: "Sales Date",
      key: "invoice_date",
      dataIndex: "invoice_date",

      render: (_, data) => (
        <>{moment(data?.invoice_date).format("DD MMM YYYY")} </>
      ),
      width: 135,
      align: "center",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);

        if (record?.comb_client)
          return (
            <Link
              to={
                client_id
                  ? `/reports/client_ledger?client_query=${client_id}`
                  : `/reports/combined_ledger?id=${combined_id}`
              }
            >
              <span>
                {record.client_name} / {record.client_entry_id}
              </span>
            </Link>
          );
      },
      width: 150,
    },
    {
      title: "Sales Price",
      dataIndex: "net_total",
      key: "net_total",
      width: 120,
      align: "right",
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),

      render: (curr) => (
        <span className="color-sales">{formatAmount(curr)}</span>
      ),
    },
    {
      title: "Rec Amount",
      dataIndex: "invclientpayment_amount",
      key: "invclientpayment_amount",
      align: "right",
      width: 120,
      render: (curr) => (
        <span className="color-collection">{formatAmount(curr)}</span>
      ),
    },
    {
      title: "Due Amount",
      align: "right",
      width: 120,
      render(_, record) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: "red" }}>
            {formatAmount(
              Number(record?.net_total) -
                Number(record?.invclientpayment_amount)
            )}
          </span>
        ) : (
          <Tag color="cyan" icon={<DollarOutlined />}>
            PAID
          </Tag>
        );
      },
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
    },
    {
      title: "MR. No.",
      dataIndex: "money_receipt_num",
      key: "money_receipt_num",
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num
            ?.split(",")
            .slice(0, 3)
            .map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          {data?.money_receipt_num?.split(",").length > 3 && <span>...</span>}
        </span>
      ),
    },

    {
      title: "Sales by",
      dataIndex: "sales_by",
      key: "sales_by",
      width: 150,
    },

    {
      title: "Action",
      key: "operation",
      width: 200,

      render: (_, data, index) => {
        return (
          <Space size="small">
            {permission?.["read:any"] && (
              <Link
                to={viewLink + "/" + data?.invoice_id}
                state={location.pathname}
              >
                <Button size="small" type="primary">
                  <Text style={{ color: "#ffffff" }}>View</Text>
                </Button>
              </Link>
            )}

            {!data.sales_by || !data.comb_client ? (
              <Button
                onClick={() => {
                  setInvoiceInfo(data);
                  setAddClientModal(!addClientModal);
                }}
                size="small"
                danger
              >
                Add Info
              </Button>
            ) : (
              <>
                {!data?.invoice_is_reissued &&
                permission?.[crud.update] &&
                !data?.invoice_is_refund ? (
                  <Link
                    to={editLink + "/" + data?.invoice_id}
                    state={location.pathname}
                  >
                    <Button size="small" type="primary">
                      Edit
                    </Button>
                  </Link>
                ) : (
                  <Button size="small" type="primary" disabled>
                    Edit
                  </Button>
                )}

                {permission?.["delete:any"] && (
                  <Button
                    size="small"
                    type="primary"
                    danger
                    loading={columnIndex === index && true}
                    disabled={
                      !!data?.invoice_is_reissued || !!data?.invoice_is_refund
                    }
                  >
                    <Popconfirm
                      onConfirm={() => handleOnDelete(data?.invoice_id, index)}
                      title="Sure to delete?"
                    >
                      <Typography style={{ color: "#ffffff" }}>
                        Delete
                      </Typography>
                    </Popconfirm>
                  </Button>
                )}
                {addMRPermission && (
                  <Button
                    disabled={
                      Number(data?.net_total) <=
                        Number(data?.invclientpayment_amount) ||
                      !!data?.invoice_is_refund
                    }
                    onClick={(e) => ModalOperation(data)}
                    size="small"
                    type="primary"
                  >
                    Add Money
                  </Button>
                )}
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const handleDateRangeChange = (date: any, dateString: any) => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <>
      <BreadCrumb arrOfOption={["Invoice", "Air Ticket"]} />

      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: "25px" }}
        justify={"space-between"}
      >
        <Col span={12}>
          <Row gutter={10}>
            {permission?.["create:any"] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={createLink}>
                  <Button
                    type="primary"
                    icon={<ArrowLeftOutlined />}
                    style={{ width: "100%" }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: "flex" }}>
              <Col>
                <Reload
                  refetch={() => {
                    getAllRefetch();
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={"end"} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={"YYYY-MM-DD"}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder="🔍 Search Invoices..."
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {invoiceInfo?.invoice_id && (
        <InvoiceOtherAddMoneyModal
          invoice_id={invoiceInfo?.invoice_id}
          InvocieModalTitle={"Invoice Air Ticket"}
          disabled={false}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}

      <AddClientListInvoice
        setAddClientModal={setAddClientModal}
        addClientModal={addClientModal}
        invoiceInfo={invoiceInfo}
      />

      <Table
        size="small"
        bordered
        rowClassName={(record) => {
          const {
            invoice_is_reissued: reissue,
            invoice_is_refund: refund,
            invoice_is_void: void_inv,
          } = record;
          let className = "";

          if (void_inv) return (className = "void_inv-row-style");
          if (reissue) return (className = "reissued-row-style");
          if (refund) return (className = "refund-row-style");

          return className;
        }}
        loading={{
          spinning: dataIsLoading || isFetching,
          indicator: loadingIndicator,
        }}
        columns={columns}
        scroll={{ x: true }}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20","50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
      />
    </>
  );
};

export default ListOfInvoiceAirticket;
