import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { formatAmountAbs } from '../../../../common/utils/common.utils';
import { dateFormate } from '../../../Dashboard/Utils/formateDate';
import { ITotal_Due_Advance_ClientDataType } from '../types/Total_Due_Advance_ClientTypes';

const { Text } = Typography;
export const Total_Due_Advance_ClientUtils = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_ClientDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record, index) => (
        <Link to={`/clients/details/client-${record.client_id}`}>
          {record.client_name}
        </Link>
      ),
    },

    {
      title: 'Mobile No.',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (_, record) =>
        record.client_mobile ? record.client_mobile : null,
    },
    {
      title: 'Email No.',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) =>
        record.client_email ? record.client_email : null,
    },
    {
      title: 'Last Trans.',
      dataIndex: 'ctrxn_created_date',
      key: 'ctrxn_created_date',
      render: (curr) =>
        curr ? dateFormate(curr) : null,
    },
    {
      title: 'Present Due',
      dataIndex: 'ctrxn_lbalance',
      key: 'ctrxn_lbalance',
      align: 'right',
      render: (_, record) => Number(record.ctrxn_lbalance) < 0 ? <span className="color-due">{formatAmountAbs(record.ctrxn_lbalance)}</span> : null
    },
    {
      title: 'Present Advance',
      dataIndex: 'ctrxn_lbalance',
      key: 'ctrxn_lbalance',
      align: 'right',
      render: (_, record) => Number(record.ctrxn_lbalance) > 0 ? <span className="color-profit">{formatAmountAbs(record.ctrxn_lbalance)}</span> : null
    }
  ];
};
