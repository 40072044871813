import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import { FormInputItem } from '../../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAgentProfileListQuery } from '../api/endpoints/agentProfileEndPoints';
import { AgentProfileDataType } from '../types/agentProfileTypes';
import { columns } from '../utils/utils';

const AgentsProfile = ({ permission }: perProps) => {
  const navigate = useNavigate();
  // Get Data with Pagination and Search
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [editInfo, setEditInfo] = useState<AgentProfileDataType | null>(null);
  const dispatch = useAppDispatch();

  const showAddAgentModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: agentProfile,
    refetch,
  } = useGetAllAgentProfileListQuery(
    { ...pagination, search },
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache.data?.count;
        const dataToReturn: AgentProfileDataType[] = [];
        const agentProfileList: string[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.agent_name) {
              agentProfileList.push(element.agent_name);
            }

            dataToReturn.push({ ...element, key: i + 1 });
          }
        }
        return { ...cache, data: { dataToReturn, agentProfileList, count } };
      },
    }
  );

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Agent Profile']}
        refetch={refetch}
        reloaderSize='small'
      />

      <Row justify={'space-between'} gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col>
          {permission?.['create:any'] && (
            <Button
              type='primary'
              onClick={(e) =>
                navigate('../agents/add', { state: '/agents/profile' })
              }
              style={{ marginRight: '15px' }}
            >
              <PlusOutlined />
              Add Agent
            </Button>
          )}
        </Col>

        <FormInputItem
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          placeholder='🔍 Search by agent'
          size={6}
        />
      </Row>

      <Table
        size='small'
        bordered
        columns={columns(
          setEditInfo,
          showAddAgentModal,
          pagination,
          permission
        )}
        dataSource={agentProfile.dataToReturn}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
        scroll={{ x: true }}
        pagination={
          agentProfile?.count !== undefined && agentProfile?.count < 20
            ? false
            : {
              ...pagination,
              total: agentProfile?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
      />
    </div>
  );
};

export default AgentsProfile;
