import { Col, Descriptions, Modal, Row, Tabs } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import dayjs from "dayjs";
import React from "react";
import { HTTPResponse } from "../../../common/types/commonTypes";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import VendorLedgers from "../../Reports/ledgers/pages/VendorLedgers";
import { ISingleVendorDetails } from "../types/vendor.interfaces";

type IProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  isFetching?: boolean;
  fetchLoading?: boolean;
  singleVendor: HTTPResponse<ISingleVendorDetails> | undefined;
};
const ViewsSingleVendor = ({
  setIsModalOpen,
  isModalOpen,
  isFetching,
  fetchLoading,
  singleVendor,
}: IProps) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Vendor Views"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"1220px"}
        style={{}}
        footer={null}
        className="vendor_details_modal"
      >
        <div style={{ position: "relative", minHeight: "50vh" }}>
          {isFetching || fetchLoading ? <LoadingIndicator /> : ""}
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "Vendor Details",
                key: "1",
                children: (
                  <>
                    <Descriptions
                      size="small"
                      title="VENDOR DETAILS"
                      bordered
                      column={1}
                    >
                      <DescriptionsItem label="Name">
                        {singleVendor?.data?.vendor_name}
                      </DescriptionsItem>
                      <DescriptionsItem label="Email">
                        {singleVendor?.data?.vendor_email}
                      </DescriptionsItem>
                      <DescriptionsItem label="Mobile No">
                        {singleVendor?.data?.vendor_mobile}
                      </DescriptionsItem>
                      <DescriptionsItem label="Commission">
                        {singleVendor?.data?.vproduct_commission_rate}
                      </DescriptionsItem>
                      <DescriptionsItem label="Amount">
                        {singleVendor?.data?.vendor_lbalance}
                      </DescriptionsItem>
                      <DescriptionsItem label="Fixed Advance">
                        {singleVendor?.data?.vendor_fixed_advance}
                      </DescriptionsItem>
                      <DescriptionsItem label="Credit Limit">
                        {singleVendor?.data?.vendor_credit_limit}
                      </DescriptionsItem>
                      <DescriptionsItem label="Vendor Address">
                        {singleVendor?.data?.vendor_address}
                      </DescriptionsItem>
                      <DescriptionsItem label="Bank Guarantee">
                        {singleVendor?.data?.vendor_bank_guarantee}
                      </DescriptionsItem>
                      <DescriptionsItem label="Vendor start date">
                        {singleVendor?.data?.vendor_start_date &&
                          dayjs(singleVendor?.data?.vendor_start_date).format(
                            "DD-MMM-YYYY"
                          )}
                      </DescriptionsItem>
                      <DescriptionsItem label="Vendor end date">
                        {singleVendor?.data?.vendor_end_date &&
                          dayjs(singleVendor?.data?.vendor_end_date).format(
                            "DD-MMM-YYYY"
                          )}
                      </DescriptionsItem>
                      <DescriptionsItem label="Registration Date">
                        {singleVendor?.data?.vendor_registration_date &&
                          dayjs(
                            singleVendor?.data?.vendor_registration_date
                          ).format("DD-MMM-YYYY")}
                      </DescriptionsItem>
                      <DescriptionsItem label="Created By">
                        {singleVendor?.data?.user_full_name}
                      </DescriptionsItem>
                    </Descriptions>

                    <h3 style={{ marginTop: 25 }}>Vendor Products:</h3>

                    <Row>
                      {singleVendor?.data?.vendor_products.map(
                        (item, index) => {
                          return (
                            <Col lg={6}>
                              {index + 1}. {item.product_name}
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </>
                ),
              },
              {
                label: "Ledger",
                key: "2",
                children: <VendorLedgers vendorInfo={singleVendor?.data} />,
              },
            ]}
          />
        </div>
      </Modal>
    </>
  );
};

export default ViewsSingleVendor;
