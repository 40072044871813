import {
  DeleteOutlined,
  EditOutlined,
  ThunderboltOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  FloatButton,
  Modal,
  Row,
  Typography,
  message,
  theme,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { useAppSelector } from "../../../../app/hooks";
import { InvoiceFooter } from "../../../../common/Invoice/InvoiceFooter";
import {
  InvoiceHeader,
  TitleCenter,
} from "../../../../common/Invoice/InvoiceHeader";
import { IViewInvoiceDetails } from "../../../../common/types/commonInterfaces";
import { Fixed2 } from "../../../../common/utils/common.utils";
import {
  useCommonDeleteInvoiceMutation,
  useSendEmailMutation,
} from "../../../../modules/IATA_AIR_TICKET/api/endpoints/airticketInvoiceEndpoints";
import InvoiceCostSubtotal from "../InvoiceCostSubtotal";
import MakeFakeInvoice from "./edited_invoice/MakeFakeInvoice";

export type HeightRefsType = {
  header_ref: React.RefObject<HTMLDivElement>;
  subtotal_ref: React.RefObject<HTMLDivElement>;
  signiture_ref: React.RefObject<HTMLDivElement>;
};

type Props = {
  invoiceData: IViewInvoiceDetails;
  viewInvoicePrintableRef: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
  heightRefs: HeightRefsType;
  showPaxAndFlightButton?: boolean;
  showFlightDetails?: boolean;
  showPaxDetails?: boolean;
  setShowPaxDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFlightDetails?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const officeInfo = {
  name: "M360 ICT",
  address1: "3RD FLOOR FLAT-F2(ROAD 07 HOUSE 74)",
  address2: "BANANI, DHAKA 1213",
  modile: " +88-01715248942",
};

export const invoiceViewStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "rgba(211, 211, 211, 0.2)",
  minHeight: "100vh",
  padding: "10px 0",
};
export const a4sizeStyle: React.CSSProperties = {
  minHeight: "11in",
  width: "8.27in",
  fontSize: "11px",
  background: "#fff",
  margin: "0px auto",
  boxSizing: "border-box",
  padding: "0px 15px",
};
export const a4sizeStyleHalfPage: React.CSSProperties = {
  minHeight: "5.75in",
  width: "8.27in",
  boxSizing: "border-box",
  fontSize: "12px",
  background: "#fff",
  margin: "0 auto",
};

const CommonViewInvoice = ({
  invoiceData,
  viewInvoicePrintableRef,
  children,
  heightRefs,
  showPaxAndFlightButton,
  setShowFlightDetails,
  setShowPaxDetails,
  showFlightDetails,
  showPaxDetails,
}: Props) => {
  const {
    client_name,
    invoice_walking_customer_name,
    client_address,
    client_mobile,
    client_email,
    invoice_no,
    invoice_date,
    invoice_sales_date,
    employee_full_name,
    refunds,
    invoice_category_id,
    invoice_pay,
    invoice_net_total,
    invoice_due_date,
    company_name,
    agent_name,
    invoice_reissue_client_type,
    invoice_is_refund,
    invoice_reference,
  } = invoiceData;

  const result = invoiceData?.billing_info?.reduce(
    (acc, item) => {
      if (item?.airticket_is_reissued === 1) {
        acc.is_reissued = true;
      }
      if (item?.airticket_is_refund === 1) {
        acc.is_refund = true;
      }
      if (item?.airticket_is_void === 1) {
        acc.is_void = true;
      }

      if (acc?.is_reissued && acc.is_refund && acc.is_void) {
        return acc;
      }

      return acc;
    },
    { is_reissued: false, is_refund: false, is_void: false }
  );

  // Invoice Action
  const userID = useAppSelector((state) => state.user?.user_id);
  const appConfig = useAppSelector((state) => state.configSlice);
  const OrgInfo = useAppSelector((state) => state.user?.organization_info);
  const OrgOwnerName = useAppSelector((state) => state.user?.user_full_name);

  const navigate = useNavigate();
  const invoice_id = Fixed2(useParams().id);
  const [deleteInvoice, { isSuccess, isLoading }] =
    useCommonDeleteInvoiceMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fakeEditModal, setFakeEditModal] = useState(false);
  const [floatOpen, setFloatOpen] = useState(true);

  const handleEdit = () => {
    if (invoice_reissue_client_type === "EXISTING") {
      navigate(
        "../" +
          findInvoiceRoute(invoice_category_id) +
          "/" +
          "edit" +
          "/existing/" +
          invoice_id,
        {
          state: `/${findInvoiceRoute(invoice_category_id)}`,
        }
      );
    } else {
      navigate(
        "../" +
          findInvoiceRoute(invoice_category_id) +
          "/" +
          "edit" +
          "/" +
          invoice_id,
        {
          state: `/${findInvoiceRoute(invoice_category_id)}`,
        }
      );
    }
  };

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    const API_URL = findInvoiceDeleteRoute(invoice_category_id);
    if (invoice_pay || refunds?.client_refund?.length) {
      message.error("Action Restricted: Invoice Already Paid or Refunded !");
    } else {
      deleteInvoice({ id: invoice_id, user_id: userID, url: API_URL });
    }
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  const handleWhatsApp = () => {
    const phoneNumber = client_mobile;
    const message = `
Hello ${client_name},

We hope this message finds you well. This is a friendly reminder regarding your recent invoice from ${
      OrgInfo?.org_name
    }. We greatly appreciate your business and would like to provide you with the following details:

Invoice Number: ${invoice_no || "N/A"}
Invoice Date: ${dayjs(invoice_sales_date).format("DD-MMM-YYYY") || "N/A"}
Due Date: ${dayjs(invoice_due_date).format("DD-MMM-YYYY") || "N/A"}
Total Amount: ${invoice_net_total || "N/A"}


Please review the invoice summary. If you have any questions or concerns regarding the invoice or need assistance with the payment process, please don't hesitate to reach out to our dedicated support team at ${
      OrgInfo?.org_mobile || "N/A"
    }.


Thank you for choosing ${
      OrgInfo?.org_name || "N/A"
    } for your travel needs. We value your business and look forward to serving you again in the future.

Best regards,
${OrgOwnerName}
${OrgInfo?.org_name}
${OrgInfo?.org_mobile}
`;

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  // Email Send APIs
  const [
    sendEmail,
    { isError: emailError, isSuccess: emailSuccess, isLoading: emailLoading },
  ] = useSendEmailMutation();

  const [emailModal, setEmailModal] = useState<boolean>(false);

  const handleEmail = () => {
    setEmailModal(!emailModal);
  };

  const confirmSendEmail = () => {
    sendEmail({ email: client_email, id: invoice_id });
  };

  useEffect(() => {
    if (emailSuccess) {
      message.success("Email Send Successfully Done");
    }

    if (emailError) {
      message.error("Can't Send Email Now");
    }
  }, [emailSuccess, emailError]);

  useEffect(() => {
    if (isSuccess) {
      message.success("Invoice Delete Successfully");
      setIsModalOpen(false);
      navigate(-1);
    }
  }, [isSuccess]);

  const { tac_inv_in, tac_inv_sd } = appConfig;

  return (
    <ViewInvoiceStyle style={invoiceViewStyle}>
      <div>
        {showPaxAndFlightButton && (
          <Row justify={"end"} className="mb-5">
            <Col className="commonBox">
              <Button size="small">
                <Checkbox
                  checked={showFlightDetails}
                  onChange={(e) =>
                    setShowFlightDetails &&
                    setShowFlightDetails(e.target.checked)
                  }
                >
                  {showFlightDetails
                    ? "Hide Flight Details"
                    : "Show Flight Details"}
                </Checkbox>
              </Button>
            </Col>
            <Col style={{ marginLeft: "10px" }}>
              <Button size="small">
                <Checkbox
                  checked={showPaxDetails}
                  onChange={(e) =>
                    setShowPaxDetails && setShowPaxDetails(e.target.checked)
                  }
                >
                  {!showPaxDetails ? "Show Pax Details" : "Hide Pax Details"}
                </Checkbox>
              </Button>
            </Col>
          </Row>
        )}

        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <div
            ref={viewInvoicePrintableRef}
            style={{ ...a4sizeStyle, position: "relative" }}
          >
            <header ref={heightRefs.header_ref}>
              <InvoiceHeader />

              <TitleCenter title="Invoice" />

              <Row justify={"space-between"} align="middle">
                <Col
                  style={{
                    color: "#fff",

                    width: "50%",
                  }}
                >
                  <Typography style={{ fontWeight: "bolder" }}>
                    INVOICE TO
                  </Typography>
                  {(client_name || company_name) && (
                    <Typography.Text className="changeFontBlock fs-12">
                      {company_name ? "Company Name : " : "Name : "}
                      {company_name ? company_name : client_name}
                      {invoice_walking_customer_name &&
                        "/" + invoice_walking_customer_name}
                    </Typography.Text>
                  )}
                  {client_address && (
                    <Typography.Text className="changeFontBlock fs-12">
                      Address : {client_address}
                    </Typography.Text>
                  )}
                  {client_email && (
                    <Typography.Text className="changeFontBlock fs-12">
                      Email : {client_email}
                    </Typography.Text>
                  )}
                  {client_mobile && (
                    <Typography.Text className="changeFontBlock fs-12">
                      Mobile : {client_mobile}
                    </Typography.Text>
                  )}

                  {invoice_reference && (
                    <Typography.Text className="changeFontBlock fs-12">
                      Reference : {invoice_reference}
                    </Typography.Text>
                  )}
                </Col>

                <Col>
                  {invoice_is_refund || refunds?.client_refund?.length ? (
                    <span style={{ color: "red" }}>Refunded</span>
                  ) : (
                    ""
                  )}

                  <Typography.Text className="changeFontBlock fs-12">
                    Invoice Date : {dayjs(invoice_date).format("DD MMM YYYY")}
                  </Typography.Text>
                  <Typography.Text className="changeFontBlock fs-12">
                    {tac_inv_in || "Invoice No"}: {invoice_no}
                  </Typography.Text>
                  <Typography.Text className="changeFontBlock fs-12">
                    {tac_inv_sd || "Sales Date"} :{" "}
                    {dayjs(invoice_sales_date).format("DD MMM YYYY")}
                  </Typography.Text>
                  {invoice_due_date && (
                    <Typography.Text className="changeFontBlock fs-12">
                      {"Due Date"} :{" "}
                      {dayjs(invoice_due_date).format("DD MMM YYYY")}
                    </Typography.Text>
                  )}
                  <Typography.Text className="changeFontBlock fs-12">
                    Sales By : {employee_full_name}
                  </Typography.Text>
                  {agent_name && (
                    <Typography.Text className="changeFontBlock fs-12">
                      {"Agent Name:"}
                      {agent_name}
                    </Typography.Text>
                  )}
                </Col>
              </Row>
            </header>

            <div>
              {children}
              <div style={{ paddingBottom: 40 }} ref={heightRefs.subtotal_ref}>
                <InvoiceCostSubtotal invoice_info={invoiceData} />
              </div>
            </div>

            {/* Footer */}

            <div ref={heightRefs.signiture_ref}>
              <InvoiceFooter
                authorized_by={invoiceData.authorized_by}
                prepared_by={invoiceData.prepared_by}
              />
            </div>
          </div>
        </ConfigProvider>

        <FloatButton.Group
          trigger="click"
          open={floatOpen}
          onClick={(e) => setFloatOpen(!floatOpen)}
          type="primary"
          style={{ right: 24 }}
          icon={<ThunderboltOutlined />}
        >
          <FloatButton
            onClick={handleWhatsApp}
            tooltip={"Message on whatsapp"}
            icon={<WhatsAppOutlined style={{ color: "#3ec868" }} />}
          />
          {/* <FloatButton
          tooltip={'Send email'}
          onClick={handleEmail}
          icon={<FontAwesomeIcon style={iconStyle} icon={faMessage} />}
        /> */}

          {!(result?.is_refund || result?.is_reissued || result?.is_void) ? (
            <FloatButton
              onClick={handleEdit}
              tooltip={"Edit invoice"}
              icon={<EditOutlined style={{ color: "blue" }} />}
            />
          ) : (
            ""
          )}

          {!(result?.is_refund || result?.is_reissued || result?.is_void) ? (
            <FloatButton
              onClick={handleDelete}
              tooltip={"Delete invoice"}
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          ) : (
            ""
          )}
        </FloatButton.Group>

        <Modal
          centered
          title="Are you sure you want to delete this invoice? "
          open={isModalOpen}
          onOk={handleConfirmDelete}
          onCancel={handleCancelDelete}
          footer={[
            <Button key="cancel" onClick={handleCancelDelete}>
              Cancel
            </Button>,
            <Button
              loading={isLoading}
              danger
              key="delete"
              type="primary"
              onClick={handleConfirmDelete}
            >
              Delete
            </Button>,
          ]}
        >
          <p>This action cannot be undone.</p>
        </Modal>

        <Modal
          centered
          title="Are you sure you want to send Email?"
          open={emailModal}
          onOk={confirmSendEmail}
          onCancel={handleEmail}
          footer={[
            <Button key="cancel" onClick={handleEmail}>
              Cancel
            </Button>,
            <Button
              loading={emailLoading}
              type="primary"
              onClick={confirmSendEmail}
            >
              Send
            </Button>,
          ]}
        >
          <p>
            An email will be sent to{" "}
            <Typography.Text strong> {client_email} </Typography.Text>
          </p>
        </Modal>

        <MakeFakeInvoice
          invoiceData={invoiceData}
          setFakeEditModal={setFakeEditModal}
          fakeEditModal={fakeEditModal}
        />
      </div>
    </ViewInvoiceStyle>
  );
};

export default CommonViewInvoice;

const ViewInvoiceStyle = styled.div``;

type CategoryID = number;
type RouteName = string;

function findInvoiceRoute(
  invoiceCategoryId: CategoryID
): RouteName | undefined {
  const categoryNames: Record<CategoryID, RouteName> = {
    1: "airticket",
    2: "noncomairticket",
    3: "reissueairticket",
    4: "tour/invoice",
    5: "invoiceother",
    10: "visa",
    26: "invoiceumrah",
    30: "hajjpreregistration",
    31: "invoicehajj",
  };

  return categoryNames[invoiceCategoryId];
}

function findInvoiceDeleteRoute(
  invoiceCategoryId: CategoryID
): RouteName | undefined {
  const categoryNames: Record<CategoryID, RouteName> = {
    1: "invoice-air-ticket",
    2: "invoice-non-commission",
    3: "reissue",
    4: "tour-package",
    5: "invoice-others/delete",
    10: "invoice-visa",
    26: "invoice-ummrah",
    30: "invoice_hajj_pre",
    31: "invoic-hajj/delete",
  };

  return categoryNames[invoiceCategoryId];
}
