import { Col } from "antd";
import { useVendorDetailsQuery } from "../Api/Endpoints/DashboardEndpoints";
import VendorDetailsSkeleton from "../Utils/VendorDetailsSkeleton";
import VendorCharts from "./VendorCharts";
import VendorDetails from "./VendorDetails";

const VendorInfo = ({ isDarkMode }: any) => {
  const { data, isLoading } = useVendorDetailsQuery();
  return (
    <>
      {isLoading ? (
        <VendorDetailsSkeleton />
      ) : (
        <>
          <Col xl={12} md={12} sm={24}>
            <VendorCharts isDarkMode={isDarkMode} />
          </Col>

          <Col xl={12} md={24}>
            <VendorDetails
              data={data}
              isLoading={isLoading}
              isDarkMode={isDarkMode}
            />
          </Col>
        </>
      )}
    </>
  );
};

export default VendorInfo;
